<!-- eslint-disable no-console -->
<script>
import Swal from "sweetalert2";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Logistic",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        api: "logistic_company",
        export: "logistic_company/export",
      },
      tabIndex: 0,
      tabStatus: null,
      tableData: [],
      title: "Logistic Company",
      items: [
        {
          text: "Logistic Company",
          href: "/logistic",
        },
        {
          text: "View",
          href: "/logistic",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      filtering: {
        status: "",
      },
      add_ctx: null,
      fields: [
        { key: "code", label: "Code" },
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Telephone" },
        { key: "city.name", label: "Kota" },
        { key: "status_account", label: "Status Account" },
        { key: "status_document", label: "Status Document" },
        { key: "action" },
      ],
      routeBase: "logistic",
      counter: {
        menunggu_upload_dokumen: null,
        menunggu_persetujuan: null,
        disetujui: null,
        ditolak: null,
      },
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    let _ = this;
    this.totalRows = this.items.length;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.$toast(_.$route.params.title, {
          icon: _.$route.params.icon,
          type: _.$route.params.event,
        });
      }
    }
  },
  methods: {
    checkVariant(value) {
      return this.tabStatus === value ? "danger" : "outline-danger";
    },

    exportExcel() {
      let _ = this;
      let key = this.add_ctx.filter ? this.add_ctx.filter : "";
      let url = _.config.export;
      api
        .get(url, {
          params: {
            orderby: "id",
            sort: "desc",
            key: key,
            account_status: this.filtering.status,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response) {
            let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "LogisticCompanyReport" + new Date();
            link.click();
          }
        });
    },
    getItems(ctx, callback) {
      const _ = this;

      let statusParam = null;
      if (this.tabStatus === null) {
        statusParam = null;
      } else {
        switch (this.tabStatus) {
          case 1:
            statusParam = "menunggu upload dokumen";
            break;
          case 2:
            statusParam = "menunggu persetujuan";
            break;
          case 3:
            statusParam = "disetujui";
            break;
          case 4:
            statusParam = "ditolak";
            break;
        }
      }

      this.add_ctx = ctx;
      let filter = ctx.filter ? "&key=" + ctx.filter : "";
      _.sortBy = ctx.sortBy !== null ? _.sortBy : ctx.sortBy;
      let sort = ctx.sortDesc == true ? "asc" : "desc";

      let url = null;
      if (statusParam) {
        url =
          _.config.api +
          "?per_page=" +
          _.perPage +
          "&page=" +
          _.currentPage +
          "&orderby=" +
          _.sortBy +
          "&sort=" +
          sort +
          "&account_status=" +
          _.filtering.status +
          "&document_status=" +
          statusParam +
          filter;
      } else {
        url =
          _.config.api +
          "?per_page=" +
          _.perPage +
          "&page=" +
          _.currentPage +
          "&orderby=" +
          _.sortBy +
          "&sort=" +
          sort +
          "&account_status=" +
          _.filtering.status +
          filter;
      }

      api
        .get(url)
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = consume.per_page;
          _.currentPage = consume.current_page;
          _.totalRows = consume.total_data;
          _.counter = consume.counter;
          callback(consume.rows || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    gotoShowPage(id) {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/" + id + "/show");
    },

    goToAddPage() {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/add");
    },

    goToEditPage(id) {
      let _ = this;
      _.$router.push("/" + _.routeBase + "/" + id + "/edit");
    },

    deleteData(id) {
      let _ = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          api
            .delete(_.config.api + "/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Dihapus",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableFe.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak bisa di hapus",
                text: err.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    changePerPage() {
      const _ = this;
      _.$refs.tableFe.refresh();
    },

    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
  },
  watch: {
    "filtering.status"() {
      let _ = this;
      _.$refs.tableFe.refresh();
    },

    tabIndex() {
      if (this.tabStatus === null) {
        if (this.currentPage === 1) {
          this.getItems(this.add_ctx);
        } else {
          this.currentPage = 1;
        }
      } else {
        this.tabStatus = null;
      }
      this.$refs.tableFe.refresh();
    },
    tabStatus() {
      if (this.currentPage === 1) {
        this.getItems(this.add_ctx);
      } else {
        this.currentPage = 1;
      }
      this.$refs.tableFe.refresh();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <b-tabs
          align="left"
          justified
          nav-class="nav-tabs-custom"
          v-model="tabIndex"
        >
          <b-tab title="Semua"></b-tab>
          <b-tab>
            <template v-slot:title>
              <span
                v-if="
                  counter.menunggu_upload_dokumen !== 0 ||
                  counter.menunggu_upload_dokumen !== 0 ||
                  counter.disetujui !== 0 ||
                  counter.ditolak !== 0
                "
                class="badge-notif mr-2"
                data-badge=""
                >Status Document &nbsp;
              </span>
              <span v-else> Status Document</span>
            </template>
          </b-tab>
        </b-tabs>
      </div>
      <div class="card col-md-12">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-10 col-md-12">
              <div id="tickets-table_length" class="dataTables_length">
                <div class="row" v-if="tabIndex == 1">
                  <div class="col-md-12">
                    <b-tabs class="contianer-fluid navtab-bg" justified pills>
                      <b-tab @click="tabStatus = null">
                        <template v-slot:title>
                          <span class="font-weight-bolder">Semua</span>
                        </template>
                      </b-tab>
                      <b-tab @click="tabStatus = 1">
                        <template v-slot:title>
                          <span class="font-weight-bolder no-wr"
                            >Menunggu Upload Dokumen &nbsp;</span
                          >
                          <span
                            v-show="counter.menunggu_upload_dokumen !== 0"
                            class="badge badge-pill py-1"
                            :class="
                              tabStatus == 1 ? 'badge-light' : 'badge-primary'
                            "
                            >{{ counter.menunggu_upload_dokumen }}</span
                          >
                        </template>
                      </b-tab>

                      <b-tab @click="tabStatus = 2">
                        <template v-slot:title>
                          <span class="font-weight-bolder no-wr"
                            >Menunggu Persetujuan &nbsp;</span
                          >
                          <span
                            v-show="counter.menunggu_persetujuan !== 0"
                            class="badge badge-pill py-1"
                            :class="
                              tabStatus == 2 ? 'badge-light' : 'badge-primary'
                            "
                            >{{ counter.menunggu_persetujuan }}</span
                          >
                        </template>
                      </b-tab>

                      <b-tab @click="tabStatus = 3">
                        <template v-slot:title>
                          <span class="font-weight-bolder no-wr"
                            >Disetujui &nbsp;</span
                          >
                          <span
                            v-show="counter.disetujui !== 0"
                            class="badge badge-pill py-1"
                            :class="
                              tabStatus == 3 ? 'badge-light' : 'badge-primary'
                            "
                            >{{ counter.disetujui }}</span
                          >
                        </template>
                      </b-tab>

                      <b-tab @click="tabStatus = 4">
                        <template v-slot:title>
                          <span class="font-weight-bolder no-wr"
                            >Ditolak &nbsp;</span
                          >
                          <span
                            v-show="counter.ditolak !== 0"
                            class="badge badge-pill py-2"
                            :class="
                              tabStatus == 4 ? 'badge-light' : 'badge-primary'
                            "
                            >{{ counter.ditolak }}</span
                          >
                        </template>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-2">
              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <div v-if="can('Logistic Company store')">
                      <b-button
                        @click="goToAddPage"
                        variant="primary"
                        class="pl-3 pr-3"
                        >Tambah</b-button
                      >
                    </div>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div class="d-flex justify-content-end">
                    <div class="mr-2">
                      <select class="form-control" v-model="filtering.status">
                        <option value="">Semua Status</option>
                        <option value="aktif">Aktif</option>
                        <option value="tidak aktif">Tidak Aktif</option>
                        <option value="suspend">Suspend</option>
                      </select>
                    </div>
                    <div class="mr-2">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Pencarian..."
                        class="form-control mr-2"
                      ></b-form-input>
                    </div>
                    <div v-if="can('Logistic Company download')">
                      <b-button variant="primary" @click="exportExcel"
                        >Download Excel</b-button
                      >
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive">
                <b-table
                  :items="getItems"
                  :fields="fields"
                  responsive="lg"
                  table-class="text-nowrap"
                  show-empty
                  striped
                  hover
                  :perPage="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  @changePerPage="changePerPage"
                  ref="tableFe"
                >
                  <template #cell(status_account)="data">
                    <span class="text-capitalize">{{
                      data.item.account_status
                    }}</span>
                  </template>
                  <template #cell(status_document)="data">
                    <span class="text-capitalize">{{
                      data.item.document_status
                    }}</span>
                  </template>
                  <template #cell(action)="data">
                    <div style="min-width: 80px">
                      <b-button
                        v-if="can('Logistic Company read')"
                        v-b-tooltip.hover.top="'Lihat Detail'"
                        variant="secondary"
                        size="sm"
                        class="mr-1"
                        @click="gotoShowPage(data.item.id)"
                      >
                        <i class="ti-eye"></i>
                      </b-button>

                      <b-button
                        v-if="can('Logistic Company update')"
                        v-b-tooltip.hover.top="'Edit'"
                        variant="warning"
                        size="sm"
                        class="mr-1"
                        @click="goToEditPage(data.item.id)"
                      >
                        <i class="ti-pencil"></i>
                      </b-button>

                      <b-button
                        v-if="can('Logistic Company delete')"
                        v-b-tooltip.hover.top="'Hapus'"
                        variant="light"
                        size="sm"
                        @click="deleteData(data.item.id)"
                      >
                        <i class="ti-trash"></i>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        class="ml-1 mr-1"
                        :options="pageOptions"
                      ></b-form-select
                      >entries
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
